.cleverreach-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255,255,255,.68);
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        position: relative;
        display: block !important;
        max-width: 500px;
        padding: 30px 30px 10px;
        background: #0062ad;
        background: radial-gradient(circle at center, rgb(0, 159, 227) 30%, #0062ad);
        box-shadow: 7px 7px 5px 0 rgba(0,0,0,0.5);
        color: #fff;
    }

    button.close {
        position: absolute;
        top: 15px;
        right: 20px;
        width: 30px;
        height: 30px;
        padding: 0;
        text-indent: -999em;
        overflow: hidden;
        background: transparent;
        border: none;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            background: #fff;
            height: 1px;
            width: 30px;
            transform: rotateZ(45deg);
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            background: #fff;
            height: 1px;
            width: 30px;
            transform: rotateZ(-45deg);
        }
    }

    h2, h3 {
        margin: 0 auto 1em;
        font-size: 30px;
        line-height: 38px;
        color: #fff;
        text-align: center;
    }

    .widget {
        text-align: center;

        input[type=email] {
            width: 100%;
            margin: 15px 0;
            padding: 2px 5px;
            color: #fff;
            background: transparent;
            border: 1px solid #fff;
            box-sizing:border-box;
        }

        button {
            margin: 0;
            padding: 3px 10px;
            background: #fff;
            border: 0;
            color: #0062ad;
        }
    }
}
